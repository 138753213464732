<template>
  <div class="contents">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 mb-2 sticky">
          <div
            class="breadcrumb-main p-3"
            style="background-color: #f4f5f7 !important"
          >
            <h4 class="text-capitalize breadcrumb-title">
              All Questions
              <ul class="atbd-breadcrumb nav">
                <li class="atbd-breadcrumb__item">
                  <router-link to="/">
                    <span class="la la-home"></span>
                  </router-link>
                  <span class="breadcrumb__seperator">
                    <span class="la la-slash"></span>
                  </span>
                </li>
                <li class="atbd-breadcrumb__item">
                  <span class="bread-item" style="color: #9299b8"
                    >Questions</span
                  >
                  <span class="breadcrumb__seperator">
                    <span class="la la-slash"></span>
                  </span>
                </li>
                <li class="atbd-breadcrumb__item">
                  <span class="bread-item">Review</span>
                </li>
              </ul>
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid overlay-clipped">
      <div class="row">
        <LoaderOverlay
          :active="true"
          v-if="!asyncLoader && loadingQuestions"
          :zIndex="99"
          position="absolute"
          style="height: 100%"
        />
        

        <div class="col-lg-12">
          <div
            class="
              userDatatable
              orderDatatable
              global-shadow
              border
              py-30
              px-sm-30 px-20
              bg-white
              radius-xl
              w-100
              mb-30
            "
          >
            <div
              class="
                project-top-wrapper
                d-flex
                justify-content-between
                flex-wrap
                mb-25
                mt-n10
              "
            >
              <div
                class="
                  d-flex
                  align-items-center
                  flex-wrap
                  w-100p
                  col-sm-12
                "
              >
                
                  <form @submit.prevent="search" class="d-flex align-items-center mt-10">
                    <div class="project-search order-search global-shadow">
                      <div class="order-search__form">
                          <i class="las la-search"></i>
                          <input
                            class="form-control mr-sm-2 border-0 box-shadow-none"
                            type="search"
                            placeholder="Search by description"
                            aria-label="Search"
                            v-model="queryString"
                          />
                      </div>
                     </div>
                    <button class="btn btn-primary btn-rounded ml-2" type="submit" v-if="!resetFlag">Go</button>
                  </form>
                    <button class="btn btn-primary btn-rounded ml-2 mt-2" type="button" @click="resetSearch" v-if="resetFlag">Reset</button>
               
                <!-- End: .project-search -->
                <div
                  class="
                    project-category
                    d-flex
                    align-items-center
                    ml-md-30
                    mt-xl-10 mt-15
                  "
                >
                  <p
                    class="fs-14 color-gray text-capitalize mb-10 mb-md-0 mr-10"
                  >
                    Status :
                  </p>
                  <div class="project-tap order-project-tap global-shadow">
                    <ul class="nav px-1" id="ap-tab" role="tablist">
                      <li
                        class="nav-item d-flex align-items-center"
                        @click="
                          params = { level: 2048, status: 0 };
                          level = 2048;
                          status = 0;
                        "
                      >
                        <a
                          class="nav-link active"
                          id="activity-tab"
                          data-toggle="pill"
                          href="#activity"
                          role="tab"
                          aria-controls="activity"
                          aria-selected="false"
                          >Pending</a
                        >
                        <span class="badge badge-primary mr-2 br-5">{{stat.pending | humanNumber}}</span>
                      </li>
                      <li
                        class="nav-item d-flex align-items-center"
                        @click="
                          params = { level: 2048, status: 1 };
                          level = 2048;
                          status = 1;
                        "
                      >
                        <a
                          class="nav-link"
                          id="timeline-tab"
                          data-toggle="pill"
                          href="#timeline2"
                          role="tab"
                          aria-controls="timeline"
                          aria-selected="false"
                          >Approved</a>
                          <span class="badge badge-primary bg-green br-5">{{stat.successful | humanNumber}}</span>
                      </li>
                    </ul>
                  </div>

                </div>
                <div class="flex-1"></div>
               <page-size @sizeChange="sizeChange" />
               
                <!-- End: .project-category -->
              </div>
              
              <!-- End: .d-flex -->
              <div class="content-center mt-10"></div>
              <!-- End: .content-center -->
            </div>
            <div>
              <div class="row">
                  <div class="col-sm-12 col-md-3">
                    <div class="form-group"> 
                    <label>Select class</label>
                      <v-select v-model="selectedClass" item-text="nick"  item-value="cllid" label="nick" :options="classes" @input="classSelected"></v-select>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-3">
                    <div class="form-group"> 
                    <label>Select subject</label>
                      <v-select v-model="selectedSubject" item-text="name"  item-value="sulid" label="name" :options="availableSubjects" @input="getTopics" :disabled="!selectedClass"></v-select>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-5">
                    <div class="form-group"> 
                    <label>Select topic</label>
                      <v-select v-model="selectedTopic" item-text="name"  item-value="tolid" label="name" :options="topics" :disabled="!selectedSubject">
                         <template #spinner="{ sloading }">
                            <div
                              v-if="sloading"
                              style="border-left-color: rgba(88, 151, 251, 0.71)"
                              class="vs__spinner"
                            >
                              The .vs__spinner class will hide the text for me.
                            </div>
                          </template>
                      </v-select>
                    </div>
                  </div>
                  <div>
                    <div class="form-group"> 
                    <label>.</label>
                      <!-- <v-select v-model="selectedTopic" item-text="nick"  item-value="tolid" label="nick" :options="[]"></v-select> -->
                    <button class="btn btn-primary btn-rounded ml-2" type="button" @click="filterQuestion">Filter</button>
                    </div>
                  </div>
              </div>
            </div>
            <!-- End: .project-top-wrapper -->
            <!-- <div class="tab-content" id="ap-tabContent">
                        <QuestionsTable :headers="headers" :questions="questions" :params="{status:0, level:2048}"></QuestionsTable>
                        
                    </div> -->
            <!-- <QuestionsTable :headers="headers"  :config="params" :level="level"></QuestionsTable> -->
            <div class="table-responsive">
              <table class="table mb-0 table-hover table-borderless border-0" v-if="questions.length">
                <thead>
                  <tr class="userDatatable-header">
                    <th v-for="(header, k) in headers" :key="k">
                      <span class="userDatatable-title">{{ header }}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(q, k) in questions" :key="k">
                    <td>
                      <div class="orderDatatable-title">
                        <a class="link">{{ paginationData.offset + k + 1 }}</a>
                      </div>
                    </td>
                    <td>
                      <div class="orderDatatable-title">
                        <a
                          class="link"
                          @click="setCurrentQlid(q.qlid, q.tolid, params, getTopicName(q.tolid))"
                          >{{ q[headers[1]] }}</a
                        >
                      </div>
                    </td>
                    <td>
                      <div class="userDatatable-content">
                        {{ questionTypeMap[q[headers[2]]] }}
                      </div>
                    </td>
                    <td>
                      <div class="orderDatatable-title d-inline-block">
                        <span
                          class="
                            bg-opacity-primary
                            color-primary
                            rounded-pill
                            userDatatable-content-status
                            active
                          "
                          >{{ q[headers[3]] }}</span
                        >
                      </div>
                    </td>
                    <td>
                      <div class="orderDatatable-status d-inline-block">
                        <span
                          class="order-bg-opacity-info rounded-pill active"
                          :class="progressColour[q[headers[4]]]"
                          >{{ progress[q[headers[4]]] }}</span
                        >
                      </div>
                    </td>
                    <td>
                      <ul
                        class="
                          orderDatatable_actions
                          mb-0
                          d-flex
                          flex-wrap
                          float-right
                        "
                      >
                        <li>
                          <a
                            @click="setCurrentQlid(q.qlid, q.ulid, params, getTopicName(q.tolid))"
                            class="view link"
                          >
                            <i class="las la-eye"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            @click="setCurrentQlid(q.qlid, q.ulid, params, getTopicName(q.tolid), true)"
                            class="view link"
                          >
                            <i class="las la-door-open"></i>
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <!-- End: tr -->
                </tbody>
              </table>
            </div>
            <no-content v-if="noData" />
          </div>
          <!-- End: .userDatatable -->
        </div>
       
        <!-- End: .col -->
      </div>
    </div>
    <Pagination
      :url="questionUrl"
      @pageChange="pageChange"
      @loading="loading"
      :urlParam="urlParam"
      :size="size"
    />
  </div>
</template>

<script>
import {
  questionTypeMap,
  progress,
  progressColour,
} from "@/utilities/constant";
import Pagination from "@/components/Pagination.vue";
import NoContent from "@/components/NoContent";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import { mapGetters, mapState } from "vuex";
import PageSize from '../../../components/PageSize.vue';
import { apiClient } from '../../../plugins/restclient';
// import  QuestionsTable  from '@/components/QuestionsTable';
export default {
  components: {
    Pagination,
    LoaderOverlay,
    NoContent,
    PageSize,
    // QuestionsTable,
  },
  data() {
    return {
      questions: [],
      questionTypeMap,
      progress,
      progressColour,
      totalPage: 0,
      currentPage: 0,
      size: 10,
      paginationData: "",
      loadingQuestions: false,
      noData: false,
      headers: ["No", "description", "type", "version", "status", "action"],
      params: {
        level: 2048,
        status: 0,
      },
      status: 0,
      level: 2048,
      topicsMeta:{},
      stat:{},
      queryString: "",
      oldQueryString:"",
      urlParam: "",
      resetFlag: false,
      selectedSubject: null,
      selectedTopic: null,
      selectedClass: null,
      availableSubjects: [],
      topics:[],
      sloading:false
    };
  },
   metaInfo: {
    title: 'Greater Favour',
    titleTemplate: '%s | Review',
  },
  computed: {
    ...mapState(["asyncLoader", "userInterest"]),
    ...mapGetters({
      subjects:"allSubjects",
      classes: "allClasses",
      // userSubjectInterest: "userSubjectInterest"
    }),
    questionUrl() {
      return `/v1/office/assessment/question/summon`;
    },
    // resetFlag(){
    //   return (this.urlParam.indexOf('q=') > -1) && this.queryString.length == 0
    // }
  },
  watch:{
    status(){
      this.resetSearch()
      this.urlParam = `&status=${this.status}&level=${this.level}`
    },
    level(){
      this.resetSearch()
      this.urlParam = `&status=${this.status}&level=${this.level}`
    },
    queryString(){
     
      if(this.oldQueryString != this.queryString){
        this.resetFlag = false
      }
    },
    selectedClass(nval){
      if(!nval){
        this.selectedTopic = null
      }
    }
  },
  created(){
    this.getStat()
    this.urlParam = `&status=${this.status}&level=${this.level}`
  },
  methods: {
    getTopics(){
      if(!this.selectedSubject){
        return
      }
      this.sloading = true
      apiClient.get(`v1/office/frame/topic/fetch/subject/${this.selectedSubject.sulid}`).then(res =>{
        this.sloading = false
        this.topics  = res.data.data
        this.selectedTopic = null
      }).catch(err => {
        this.sloading = false
        console.log(err)
      })
    },
    filterQuestion(){
      this.urlParam = `&status=${this.status}&level=${this.level}`
      if(this.selectedSubject){
        this.urlParam += `&subject=${this.selectedSubject.sulid}`
      }
      if(this.selectedClass){
        this.urlParam += `&class=${this.selectedClass.cllid}`
      }
      if(this.selectedTopic){
        this.urlParam += `&topic=${this.selectedTopic.tolid}`
      }
      console.log(this.urlParam, "prams chamge")
    },
    classSelected(){

      // this.availableSubjects = this.subjects.filter(s => s.cllid == this.selectedClass?.cllid)
      const interestIds = this.userInterest.map(i => i.thlid)
      this.availableSubjects = this.subjects.filter(s => interestIds.includes(s.thlid) ).filter(s => s.cllid == this.selectedClass.cllid)
      this.selectedSubject = null
    },
    resetSearch(){
      this.queryString = ''
      this.oldQueryString = ''

      this.urlParam = `&status=${this.status}&level=${this.level}`
      this.resetFlag = false
    
    },
    search(){
      if(!this.queryString.length){
        return
      }
      this.oldQueryString = this.queryString

      this.resetFlag = true
      this.urlParam = `&status=${this.status}&level=${this.level}&q=${this.queryString}`
    },
    getStat(){
      apiClient.get('/v1/office/assessment/question/stat').then(res =>{
        this.stat = {
          ...res.data.info.review
        }
      }).catch(() => {

      })
    },
    setCurrentQlid(qlid, ulid, qLevel, topicName, newtab) {
      
      localStorage.setItem("currentQlid", qlid )
      localStorage.setItem("topicName", topicName )
      localStorage.setItem("qLevel", qLevel )
      localStorage.setItem("currentQUlid", ulid )

      let route = this.$router.resolve({name: "questions-reviews-overview"});
      if(newtab) {
        window.open(route.href, '_blank')
      }else this.$router.push(route.href)

    },
    loading(status) {
      this.loadingQuestions = status;
    },
    sizeChange(size){
      this.size = parseInt(size)

    },
    getTopicName(tolid){
      // return this.topicsMeta[tolid]?.name ? this.topicsMeta[tolid]?.name : '-'
      return this.topicsMeta ? this.topicsMeta[tolid]?.name : '-'
    },
    pageChange(dataSet, paginationData, res) {
      this.topicsMeta = res.info.topics
      this.questions = dataSet;
      this.paginationData = paginationData;
      if (this.questions.length == 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    },
  },
};
</script>

<style></style>
